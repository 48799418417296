import { Button, Card, Paper } from "@mui/material";
import "./App.css";
import CardContent from "@mui/material/CardContent";

import "bootstrap/dist/css/bootstrap.css";

function App() {
  return (
    <div class="App" style={{ backgroundColor: "black" }}>
      <div class="container-fluid">
        <Card>
          <CardContent style={{ padding: 30 }} color="#a30002">
            <h1>The Fox Hunters</h1>
            <h3>IT Consultation</h3>
            <br />

            <h5> Develop an IT solution for your needs</h5>
          </CardContent>
        </Card>

        <Paper elevation={3} style={{ padding: 5, marginLeft: "11%" }}>
          <CardContent style={{ padding: "10px" }}>
            <Card variant="outlined">
              <CardContent>
                <Button
                  variant="outlined"
                  size="large"
                  style={{ color: "#a30002", borderColor: "#a30002" }}
                >
                  Build your solution
                </Button>

                <div class="row" style={{ padding: "10px" }}>
                  <div class="col-sm-4">
                    <Card>
                      <CardContent>
                        <h2>WIFI</h2>
                        <ul>
                          <li>WIFI provider selection</li>
                          <li>WIFI Installation</li>
                          <li>Range extending</li>
                          <li>Upgrading</li>
                        </ul>
                      </CardContent>
                    </Card>
                  </div>

                  <div class="col-sm-4 secondary">
                    <Card>
                      <h2>PC</h2>
                      <ul>
                        <li>Buying a PC for your needs</li>
                        <li>Computer speed fixing</li>
                        <li>Computer Upgrading</li>
                        <li>Virus Removal</li>
                      </ul>
                    </Card>
                  </div>

                  <div class="col-sm-4 secondary">
                    <Card>
                      <h2>General IT</h2>
                      <ul>
                        <li>Knowledge Upgrading</li>
                        <li>Elderly computer assistance</li>
                        <li>Personalized assistance</li>
                      </ul>
                    </Card>
                  </div>
                </div>
              </CardContent>
            </Card>

            <br />

            <Card variant="outlined">
              <CardContent>
                <Button
                  variant="outlined"
                  size="large"
                  style={{ color: "#a30002", borderColor: "#a30002" }}
                >
                  Personal Security Consultant
                </Button>
                <br />
                <h4 style={{ padding: "10px" }}>
                  {" "}
                  1 Hr/Month cyber security specialist{" "}
                </h4>

                <div class="row" style={{ padding: "10px" }}>
                  <div class="col-sm-4">
                    <Card variant="outlined">
                      <CardContent>
                        <h4>Personal Recon</h4>
                        <ul>
                          <li>See your current cyber footprint</li>
                        </ul>
                      </CardContent>
                    </Card>
                  </div>

                  <div class="col-sm-4">
                    <Card variant="outlined">
                      <CardContent>
                        <h4>Personal IT Training</h4>
                        <ul>
                          <li>Increase your personal IT knowledge</li>
                        </ul>
                      </CardContent>
                    </Card>
                  </div>

                  <div class="col-sm-4">
                    <Card variant="outlined">
                      <CardContent>
                        <h4>Cyber attack simulation</h4>
                        <h5>Simulate someone trying to</h5>
                        
                        <br/>

                        <ul>
                          <li>Attack your personal devices</li>
                          <li>Steal your online Identity</li>
                          <li>Steal your passwords</li>
                        </ul>
                      </CardContent>
                    </Card>
                  </div>

                  <div class="col-sm-4">
                    <Card variant="outlined">
                      <CardContent>
                        <h4>Tailored Security Fixes</h4>
                        <ul>
                          <li>Secure all online accounts</li>
                          <li>Updating applications</li>
                          <li>Antivirus</li>
                          <li>OS updates</li>
                        </ul>
                      </CardContent>
                    </Card>
                  </div>
                </div>
              </CardContent>
            </Card>

            <br />

            <Card variant="outlined">
              <CardContent>
                <h1>Contact</h1>
                <h4>Email</h4>
                <h5>TheGlobalFoxHunters@Gmail.com</h5>
              </CardContent>
            </Card>
          </CardContent>
        </Paper>
      </div>
    </div>
  );
}

export default App;
