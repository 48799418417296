import React from "react";
import ReactDOM from "react-dom/client";
import "./index.css";
import App from "./App";
import reportWebVitals from "./reportWebVitals";

import { ThemeProvider, createTheme } from "@mui/material/styles";
import CssBaseline from "@mui/material/CssBaseline";
import { Button, Drawer } from "@mui/material";

const darkTheme = createTheme({
  palette: {
    mode: "dark",
    text: {
      primary: "#FFFFFF",
      secondary: "black",
    },
    secondary: {
      main: "#a30002",
    }
  },
});


const root = ReactDOM.createRoot(document.getElementById("root"));
root.render(
  <React.StrictMode>
    <ThemeProvider theme={darkTheme}>
      <CssBaseline />
      <Drawer
        sx={{
          width: "auto",
          flexShrink: 1,
          "& .MuiDrawer-paper": {
            width: "12%",
            boxSizing: "border-box",
            padding: "6px",
          },
        }}
        variant="permanent"
        anchor="left"
      >
        <br />
        <br />
        <Button variant="contained" color="error">
          The Fox Hunters
        </Button>
        <br />

        <Button variant="outlined" color="secondary">Build your It solution</Button>
        <br />

        <Button variant="outlined" color="secondary">Personal Security Consultant</Button>
      </Drawer>
      <App />
    </ThemeProvider>
  </React.StrictMode>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
